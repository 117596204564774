<template>
  <div class="base">
    <main class="main-body">
      <div class="security-body" id="login-body">
        <router-link to="/login">
          <div class="text-center">
            <img
              :src="require('../assets/images/horizontal_logo.png')"
              width="250"
              height="50"
            />
          </div>
        </router-link>

        <div class="text-center mt-4 mb-3 title">{{ $t("SIGN IN") }}</div>

        <form id="login" @submit.stop.prevent="login">
          <div class="form-group">
            <label for="email">{{ $t("Email") }}</label>
            <b-form-input
              id="example-input-1"
              :placeholder="$t(`Enter email`)"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t("This is a required field.")
            }}</b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <label for="password">{{ $t("Password") }}</label>
            <div class="password-input">
              <input
                v-if="showPassword"
                type="text"
                class="form-control"
                :placeholder="$t(`Enter Password`)"
                v-model="$v.form.password.$model"
              />
              <input
                v-else
                type="password"
                class="form-control"
                :placeholder="$t(`Enter Password`)"
                v-model="$v.form.password.$model"
              />
              <b-button class="button" @click="toggleShow">
                <i
                  :class="{
                    'fas fa-eye-slash text-info-50': showPassword,
                    'fas fa-eye text-info-50': !showPassword,
                  }"
                  aria-hidden="true"
                ></i>
              </b-button>
            </div>
            <div class="text-right">
              <router-link class="signup-btn" to="/password/forgot">
                {{ $t("Forgot Password?") }}
              </router-link>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button type="submit" class="security-btn" v-if="!isLogin">
              {{ $t("Sign In") }}
            </button>
            <button class="security-btn" v-else disabled>
              <b-spinner label="Spinning"></b-spinner>
            </button>
          </div>

          <div class="text-center mt-4 d-flex justify-content-center">
            <small>
              <span class="mr-2">Don't Have Account Yet?</span>
              <router-link class="text-primary" to="/onboard">Sign up now</router-link>
            </small>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import api from "../helper/api.js";

export default {
  data() {
    return {
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
      isLogin: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        // minLength: minLength(8)
      },
    },
  },

  mounted() {
    console.log(this.$v.form.password.$model);
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async login() {
      localStorage.clear();
      this.isLogin = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isLogin = false;
        return;
      }

      try {
        // const api = await axios.post(`${baseUrl}/login`,{
        //   email: this.form.email,
        //   password: this.form.password
        // });

        // const response = api.data;

        // localStorage.setItem("token",response.access_token);

        // this.$router.push({'name':'Wallet'});
        let params = {
          email: this.form.email,
          password: this.form.password,
        };
        const response = await api.apiCall(
          "core",
          "POST",
          "/operators/login",
          params
        );
        console.log(response);
        if (response.message.require_2fa) {
          this.$router.push({
            name: "TwoFa",
            params: { email: this.form.email },
          });
          return;
        }

        await this.getUserType();

        this.$router.push({ name: "Wallet" });
      } catch (error) {
        this.$swal("Error", error.message, "error");
        this.isLogin = false;
        console.log(error);
      }
    },

    async getUserType() {
      try {
        const response = await api.apiCall("core", "GET", "/operators");
        console.log(response);
        localStorage.setItem("userType", response.message.service_type);
        localStorage.setItem("platform_id", response.message.platform_id);
        localStorage.setItem("google2fa", response.message.google2fa);
        localStorage.setItem("platform_name", response.message.platform_name);
        localStorage.setItem("userEmail", response.message.email);
        localStorage.setItem("swagger", response.message.swagger);
        localStorage.setItem("chargeUsd",response.message.charge_usd);
        // localStorage.setItem("viewOperator",response.message.permission.SETTING || response.message.permission.INVITE);
        if (response.message.permission.ROOT != undefined) {
          localStorage.setItem("inviteOperator", true);
          localStorage.setItem("settingOperator", true);
          localStorage.setItem("root", true);
          return;
        }

        localStorage.setItem(
          "inviteOperator",
          response.message.permission.INVITE
        );
        localStorage.setItem(
          "settingOperator",
          response.message.permission.SETTING
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
@import "../css/styles.css";

#login-body {
  margin: 5% 10%;
  width: 40%;
}

.password-input {
  display: flex;
  position: relative;
  align-items: center;
}

.password-input button {
  position: absolute;
  right: 1%;
  display: flex;
  border: none;
  background-color: transparent;
  color: var(--info-50);
}

#bottom-container {
  color: #c6c8dd;
}

.signup-btn {
  color: var(--primary);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.signup-btn:hover {
  color: var(--info);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  #login-body {
    margin: 10% 5%;
    width: 100%;
  }
}
</style>
